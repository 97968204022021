import {
    CheckRedeemByCodeArgs,
    CheckRedeemByCodeResponse,
    getOfferRedeemByIdArgs,
    getOfferRedeemByIdResponse,
    getOffersRedeemedListArgs,
    getOffersRedeemedListResponse,
    UpdateOfferRedeemArgs,
    UpdateOfferRedeemResponse,
} from 'shared/types/redeemTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'

export const offerRedeem = {
    getById: (args: getOfferRedeemByIdArgs) => {
        return sendRequest<getOfferRedeemByIdResponse>({
            endpoint: serverEndPoints.offerRedeem.getById,
            args: args,
            method: 'post',
            auth: true,
        })
    },

    getList: (args: getOffersRedeemedListArgs) => {
        return sendRequest<getOffersRedeemedListResponse>({
            endpoint: serverEndPoints.offerRedeem.getList,
            args,
            method: 'post',
            auth: true,
        })
    },

    checkRedeem: (args: CheckRedeemByCodeArgs) => {
        return sendRequest<CheckRedeemByCodeResponse>({
            endpoint: serverEndPoints.offerRedeem.checkRedeem,
            args,
            method: 'post',
            auth: true,
        })
    },

    updateRedeem: (args: UpdateOfferRedeemArgs) => {
        return sendRequest<UpdateOfferRedeemResponse>({
            endpoint: serverEndPoints.offerRedeem.updateOfferRedeem,
            args,
            method: 'post',
            auth: true,
        })
    },
}
