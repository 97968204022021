export default function isNumInput(id: string) {
    const numinput = document.getElementById(id)

    const invalidChars = ['-', '+', 'e']

    if (numinput != null) {
        numinput.addEventListener('keydown', function (e) {
            if (invalidChars.includes(e.key)) {
                e.preventDefault()
            }
        })
    }
}
